"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const context_1 = require("./context");
const style_1 = require("./style");
const react_dom_1 = require("react-dom");
const PopupBox = (props) => {
    const { position = 'absolute', placement = 'bottom', align = 'start', width, height, zIndex = 10, innerStyle, children, } = props;
    const bodyNode = typeof window === 'undefined' ? null : document.querySelector('body');
    const [openerNodeBCRect, setOpenerNodeBCRect] = (0, react_1.useState)(null);
    const [innerNodeBCRect, setInnerNodeBCRect] = (0, react_1.useState)(null);
    const [opacity, setOpacity] = (0, react_1.useState)(false);
    const [mounted, setMounted] = (0, react_1.useState)(false);
    const closed = (0, react_1.useRef)(false);
    const node = (0, react_1.useRef)(null);
    const innerNode = (0, react_1.useRef)(null);
    const { opened, opener: openerNode, onClose, closingOnOutClick, closingOnEscPress, closingOnMouseLeave, } = (0, react_1.useContext)(context_1.PopupContext);
    const setOffset = (0, react_1.useCallback)(() => {
        if (!(openerNode === null || openerNode === void 0 ? void 0 : openerNode.current) || !(innerNode === null || innerNode === void 0 ? void 0 : innerNode.current))
            return;
        setOpenerNodeBCRect(openerNode.current.getBoundingClientRect());
        setInnerNodeBCRect(innerNode.current.getBoundingClientRect());
    }, [openerNode]);
    const handleClick = (0, react_1.useCallback)((e) => {
        var _a, _b;
        if (closingOnOutClick &&
            onClose &&
            opened &&
            !((_a = node.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) &&
            !((_b = openerNode === null || openerNode === void 0 ? void 0 : openerNode.current) === null || _b === void 0 ? void 0 : _b.contains(e.target))) {
            onClose();
        }
    }, [closingOnOutClick, onClose, opened, openerNode]);
    const handleKeyPress = (0, react_1.useCallback)((e) => {
        if (closingOnEscPress && opened && onClose && e.which === 27) {
            e.preventDefault();
            onClose();
        }
    }, [closingOnEscPress, onClose, opened]);
    const handleOnPointerMove = (0, react_1.useCallback)((e) => {
        var _a, _b;
        if (!closed.current &&
            opened &&
            closingOnMouseLeave &&
            onClose &&
            !((_a = node === null || node === void 0 ? void 0 : node.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) &&
            !((_b = openerNode === null || openerNode === void 0 ? void 0 : openerNode.current) === null || _b === void 0 ? void 0 : _b.contains(e.target))) {
            closed.current = true;
            onClose();
        }
    }, [closingOnMouseLeave, onClose, opened, openerNode]);
    const renderInner = (0, react_1.useMemo)(() => ((0, jsx_runtime_1.jsx)(style_1.$PopupBox, { "data-component": "PopupBox", ref: node, "$mounted": mounted, "$opened": opened, "$opacity": opacity, "$position": position, "$placement": placement, "$align": align, "$width": width, "$height": height, "$zIndex": zIndex, "$openerNodeBCRect": openerNodeBCRect, "$innerNodeBCRect": innerNodeBCRect, children: (0, jsx_runtime_1.jsx)(style_1.$PopupBoxInner, { ref: innerNode, style: innerStyle, children: children }) })), [
        align,
        children,
        height,
        innerNodeBCRect,
        innerStyle,
        mounted,
        opacity,
        opened,
        openerNodeBCRect,
        placement,
        position,
        width,
        zIndex,
    ]);
    (0, react_1.useEffect)(() => {
        setOffset();
        window.addEventListener('resize', setOffset);
        document.addEventListener('scroll', setOffset, true);
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchend', handleClick);
        document.addEventListener('keydown', handleKeyPress);
        document.addEventListener('mousemove', handleOnPointerMove);
        return () => {
            window.removeEventListener('resize', setOffset);
            document.removeEventListener('scroll', setOffset);
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchend', handleClick);
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('mousemove', handleOnPointerMove);
        };
    }, [openerNode, opened, setOffset, handleClick, handleKeyPress, handleOnPointerMove]);
    (0, react_1.useEffect)(() => {
        if (openerNode && opened) {
            setOffset();
        }
    }, [openerNode, opened, setOffset]);
    (0, react_1.useEffect)(() => setOpacity(opened && mounted), [opened, mounted]);
    (0, react_1.useEffect)(() => {
        if (!mounted && openerNodeBCRect && innerNodeBCRect)
            setMounted(true);
    }, [mounted, openerNodeBCRect, innerNodeBCRect]);
    (0, react_1.useEffect)(() => {
        if (opened && openerNodeBCRect) {
            const { x, y, top, left } = openerNodeBCRect;
            if (!Object.values({ x, y, top, left }).find((i) => i !== 0)) {
                onClose();
            }
        }
    }, [onClose, opened, openerNodeBCRect]);
    if (position === 'fixed' && bodyNode) {
        return (0, react_dom_1.createPortal)(renderInner, bodyNode);
    }
    return renderInner;
};
exports.default = PopupBox;
