import {
  Chessgun,
  chessSide,
  figureType,
  IChessgunAnalysisItem,
  IChessgunHistoryItem,
} from 'chessgun/core';
import { Dispatch } from 'react';

import {
  GameRatingMode,
  GameResult,
  GameStatus,
  IBoardData,
  IChallengeItem,
  IGamePlayer,
  ITimeControl,
  ITourGameData,
  oppMode,
  GameType,
  GameUserType,
  ITournamentGameTourResult,
  IClassifiedMove,
} from '@types';
import { useNGPageActions } from '.';
import { ICurrentSettings, TNextGameStatus } from '../_types';
import { eDoneReason } from '@store/context/_common.types';
import { ITurn } from '@store/context/_types';
import {
  ILobbyContextGame,
  IContextGameState,
} from '@store/context/lobby_context/_lobby_context.types';
import { useReactions } from './hooks/_use_reactions.hook';
import { IAlertPopup } from '@utils/hooks/_useAlertPopup.hook';

/**
 * На подумать: вынести это как enum
 * Со статусами CONNECTED, ESTABLISHING_INITIAL_CONNECTION, INITIALLY_CONNECTING, DISCONNECTED
 * ESTABLISHING_INITIAL_CONNECTION ИЛИ INITIALLY_CONNECTING — аналог isInitiallyConnectingUntilEstablished
 * INITIALLY_CONNECTING — аналог isInitialConnectionInStatusConnecting
 */
export interface IPlayerWsStatus {
  // anotherBrowserLogin?: boolean;

  /**
   * Находятся ли сокеты в состоянии
   * ДО начального установления соединения
   */
  isInitiallyConnectingUntilEstablished?: boolean;

  /**
   * Находятся ли сокеты в состоянии
   * начального установления соединения в статусе CONNECTING
   */
  isInitialConnectionInStatusConnecting?: boolean;

  /**
   * Находятся ли сокеты в состоянии разрыва соединения
   */
  disconnected?: boolean;
}

export interface INGPageContextState {
  id: string;
  boardFlipped: boolean;
  endedAnimationShown: boolean;
  drawOffered: boolean;
  drawOfferSended: boolean;
  opponentOfferedDraw: boolean;
  threefoldRepetition: boolean;
  offeredDrawsCount: number;
  lastDrawOfferedMoveIndex: number;
  isReviewMode: boolean;
  gamePgn: string;
  pgnFileName: string;
  gamePgnRequest: boolean;
  nextGameStatus: TNextGameStatus | null;
  movesHistory: (IChessgunHistoryItem | null)[];
  movesHistoryLength: number;
  lastMove: IChessgunHistoryItem | null;
  selectedMove: IChessgunHistoryItem | null;
  whiteCaptured: figureType[];
  whiteAdvantage: number;
  blackCaptured: figureType[];
  blackAdvantage: number;
  topWarnOpacity: number;
  bottomWarnOpacity: number;
  animationTime: number;
  gameEnded: boolean;
  caloriesLoss: number;
  ratingChange: number;
  newRating: number;
  rematchOffer?: IChallengeItem | null;
  rematchOfferedRequest: boolean;
  rematchOfferedCancelRequest: boolean;
  newGameOffer?: IChallengeItem | null;
  newGameOfferedCancelRequest: boolean;
  newGameOfferedRequest: boolean;
  inviteChallenge?: IChallengeItem | null;
  inviteChallengeRequest: boolean;
  inviteChallengeAcceptRequest: boolean;
  inviteChallengeDeclineRequest: boolean;
  topPlayerWarn: boolean;
  bottomPlayerWarn: boolean;
  soundPlayed: boolean;
  tourGameResult: ITournamentGameTourResult | null;
  gameResultRequest: boolean;
  readyForArena: boolean;
  timeControl?: ITimeControl;
  isAnalysisPopupOpened?: boolean;
  isGameDataLoaded?: boolean;
  reportGamePopupOpened?: boolean;
  gameReported?: boolean;
  figuresView: number;
  showAnalysis?: boolean;
  isAnalysing?: boolean;
  analysisItem?: IChessgunAnalysisItem | null;
  enableUserCommenting?: boolean;
  movesClassification: IClassifiedMove[] | null;
  movesClassificationRequest: boolean;
}

export type INGPageContextDispatch = Dispatch<INGPageAction>;

export interface INGPageContextComputed {
  myColor: chessSide;
  whitePreMoveMsLeft: number;
  blackPreMoveMsLeft: number;
  playerTurn: chessSide;
  whitePlayer: IGamePlayer | null;
  blackPlayer: IGamePlayer | null;
  gameType: GameType;
  gameUserType: GameUserType;
  endReason: eDoneReason | null;
  isAbort: boolean;
  result: GameResult | null;
  currentGame: ILobbyContextGame | null;
  engine: Chessgun | null;
  analysis: boolean | null;
  boardDataRequest: boolean;
  boardData: ITourGameData | null;
  gameStatus: GameStatus;
  uid: string | null;
  boardId: string;
  opponentMode: oppMode;
  chessboardSize: number;
  playerWsStatus: IPlayerWsStatus | null;
  opponentDisconnected: boolean;
  lossIfDisconnectedForTime: number | undefined;
  opponentDisconnectedLoseTs: number | undefined;
  gameRatingMode: GameRatingMode;
  isMultiregion: boolean;
  dateTimeOfRound: number | null;
  tournamentId: number | null;
  tournamentSlug: string | null;
  turns?: ITurn[] | undefined;
  currentSettings: ICurrentSettings | null;
  opponent: IGamePlayer | null;
  currentFen?: string;
  myPendingDraws: number;
  hasVariations: boolean;
  // datetimeOfCancel: string | null;
}

export type TNGPageActions = ReturnType<typeof useNGPageActions>;

export type INGPageReactions = ReturnType<typeof useReactions>;

export interface INGPageContextProps {
  state: INGPageContextState;
  dispatch: INGPageContextDispatch;
  computed: INGPageContextComputed;
  actions: TNGPageActions;
  reactions: INGPageReactions;
  alertPopup: IAlertPopup;
}

export type TNGPageContextReducer = React.Reducer<
  INGPageContextState,
  INGPageAction
>;

export enum eNGGamePageContextActionTypes {
  SET_CURRENT_GAME = 'set current game',
  SET_BOARD_DATA = 'set board data',
  SET_BOARD_DATA_REQUEST = 'set board data request',
  SET_GAME_PGN = 'set game pgn',
  SET_GAME_PGN_NAME = 'set game pgn name',
  SET_GAME_PGN_REQUEST = 'set game pgn request',
  SET_GAME_ZEN_MODE = 'set game zen mode',
  SET_GAME_REVIEW_MODE = 'set game review mode',
  SET_BOARD_FLIPPED = 'set board flipped',
  SET_ENDED_ANIMATION_SHOWN = 'set ended animation shown',
  SET_DRAW_OFFERED = 'set game draw offered',
  SET_DRAW_OFFER_SENDED = 'set draw offer sended',
  SET_OPPONENT_OFFERED_DRAW = 'set opponent offered draw',
  SET_THREEFOLD_REPETITION = 'set threefold repetition',
  SET_OFFERED_DRAWS_COUNT = 'set offered draws count',
  SET_LAST_DRAW_OFFERED_MOVE_INDEX = 'set last draw offered move index',
  SET_PLAYER_NOTIFY = 'set player notify',
  SET_OPPONENT_NOTIFY = 'set opponent notify',
  SET_NEXT_GAME_STATUS = 'set next game status',
  SET_MOVES_HISTORY = 'set moves history',
  SET_MOVES_HISTORY_LENGTH = 'set moves history length',
  SET_LAST_MOVE = 'set last move',
  SET_SELECTED_MOVE = 'set selected move',
  SET_WHITE_CAPTURED = 'set white captured',
  SET_WHITE_ADVANTAGE = 'set white advantage',
  SET_BLACK_CAPTURED = 'set black captured',
  SET_BLACK_ADVANTAGE = 'set black advantage',
  SET_TOP_WARN_OPACITY = 'set top warn opacity',
  SET_BOTTOM_WARN_OPACITY = 'set bottom warn opacity',
  SET_ANIMATION_TIME = 'set animation time',
  SET_GAME_ENDED = 'set game ended',
  SET_GAME_DATA_LOADED = 'set game data loaded',
  SET_CALORIES_LOSS = 'set calories loss',
  SET_RATING_CHANGE = 'set rating change',
  SET_NEW_RATING = 'set new rating',
  SET_REMATCH_OFFER = 'set rematch offer',
  SET_REMATCH_OFFERED_REQUEST = 'set rematch offered request',
  SET_REMATCH_OFFERED_CANCEL_REQUEST = 'set rematch offered cancel request',
  SET_NEW_GAME_OFFER = 'set new game offer',
  SET_NEW_GAME_OFFERED_REQUEST = 'set new game offered request',
  SET_NEW_GAME_OFFERED_CANCEL_REQUEST = 'set new game offered cancel request',
  SET_INVITE_CHALLENGE = 'set invite challenge',
  SET_INVITE_CHALLENGE_REQUEST = 'set invite challenge request',
  SET_INVITE_CHALLENGE_ACCEPT_REQUEST = 'set invite challenge accept request',
  SET_INVITE_CHALLENGE_DECLINE_REQUEST = 'set invite challenge decline request',
  SET_TOP_PLAYER_WARN = 'set top player warn',
  SET_BOTTOM_PLAYER_WARN = 'set bottom player warn',
  SET_SOUND_PLAYED = 'set sound played',
  SET_TOUR_GAME_RESULT = 'set tour game result',
  SET_GAME_RESULT_REQUEST = 'set game result request',
  SET_READY_FOR_ARENA = 'set ready for arena',
  SET_IS_ANALYSIS_POPUP_OPENED = 'set analysis popup opened',
  SET_TIME_CONTROL = 'set time control',
  SET_ID = 'set id',
  RESET_GAME = 'reset game',
  SET_REPORT_GAME_POPUP_OPENED = 'set report game popup opened',
  SET_GAME_REPORTED = 'set game reported',
  SET_FIGURES_VIEW = 'set figures view',
  SET_SHOW_ANALYSIS = 'set show analysis',
  SET_ANALYSIS_ITEM = 'set analysis item',
  SET_IS_ANALYSING = 'set is analysing',
  SET_ENABLE_USER_COMMENTING = 'set enable user commenting',
  SET_MOVES_CLASSIFICATION = 'set moves classification',
  SET_MOVES_CLASSIFICATION_REQUEST = 'set moves classification request',
}

interface INGPageActionMessage {
  type: eNGGamePageContextActionTypes;
}

interface ISetCurrentGame extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_CURRENT_GAME;
  payload: IContextGameState | null;
}

interface ISetBoardDataRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_BOARD_DATA_REQUEST;
  payload: boolean;
}

interface ISetBoardData extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_BOARD_DATA;
  payload: IBoardData | null;
}

interface ISetGameZenMode extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_ZEN_MODE;
  payload: boolean;
}

interface ISetGameReviewMode extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_REVIEW_MODE;
  payload: boolean;
}

interface ISetBoardFlipped extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_BOARD_FLIPPED;
  payload: boolean;
}

interface ISetEndedAnimationShown extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_ENDED_ANIMATION_SHOWN;
  payload: boolean;
}

interface ISetDrawOffered extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_DRAW_OFFERED;
  payload: boolean;
}

interface ISetDrawOfferSended extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_DRAW_OFFER_SENDED;
  payload: boolean;
}

interface ISetOpponentOfferedDraw extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_OPPONENT_OFFERED_DRAW;
  payload: boolean;
}

interface ISetThreefoldRepetition extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_THREEFOLD_REPETITION;
  payload: boolean;
}

interface ISetOfferedDrawsCount extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_OFFERED_DRAWS_COUNT;
  payload: number;
}

interface ISetLastDrawOfferedMoveIndex extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_LAST_DRAW_OFFERED_MOVE_INDEX;
  payload: number;
}

interface ISetGamePgn extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_PGN;
  payload: string;
}

interface ISetGamePgnName extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_PGN_NAME;
  payload: string;
}

interface ISetGamePgnRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_PGN_REQUEST;
  payload: boolean;
}

interface ISetNextGameStatus extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_NEXT_GAME_STATUS;
  payload: TNextGameStatus | null;
}

interface ISetMovesHistory extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_MOVES_HISTORY;
  payload: (IChessgunHistoryItem | null)[];
}

interface ISetMovesHistoryLength extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_MOVES_HISTORY_LENGTH;
  payload: number;
}

interface ISetLastMove extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_LAST_MOVE;
  payload: IChessgunHistoryItem | null;
}

interface ISetSelectedMove extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_SELECTED_MOVE;
  payload: IChessgunHistoryItem | null;
}

interface ISetWhiteCaptured extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_WHITE_CAPTURED;
  payload: figureType[];
}

interface ISetWhiteAdvantage extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_WHITE_ADVANTAGE;
  payload: number;
}

interface ISetBlackCaptured extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_BLACK_CAPTURED;
  payload: figureType[];
}

interface ISetBlackAdvantage extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_BLACK_ADVANTAGE;
  payload: number;
}

interface ISetTopWarnOpacity extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_TOP_WARN_OPACITY;
  payload: number;
}

interface ISetTBottomWarnOpacity extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_BOTTOM_WARN_OPACITY;
  payload: number;
}

interface ISetAnimationTime extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_ANIMATION_TIME;
  payload: number;
}

interface ISetGameEnded extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_ENDED;
  payload: boolean;
}

interface ISetIsGameDataLoaded extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_DATA_LOADED;
  payload: boolean;
}

interface ISetCaloriesLoss extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_CALORIES_LOSS;
  payload: number;
}

interface ISetRatingChange extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_RATING_CHANGE;
  payload: number;
}

interface ISetNewRating extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_NEW_RATING;
  payload: number;
}

interface ISetRematchOffer extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_REMATCH_OFFER;
  payload: IChallengeItem | null;
}

interface ISetRematchOfferedRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_REMATCH_OFFERED_REQUEST;
  payload: boolean;
}

interface ISetRematchOfferedCancelRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_REMATCH_OFFERED_CANCEL_REQUEST;
  payload: boolean;
}

interface ISetInviteChallengeAcceptRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_ACCEPT_REQUEST;
  payload: boolean;
}

interface ISetInviteChallengeDeclineRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_DECLINE_REQUEST;
  payload: boolean;
}

interface ISetInviteChallenge extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE;
  payload: IChallengeItem | null;
}

interface ISetInviteChallengeRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_REQUEST;
  payload: boolean;
}

interface ISetNewGameOffer extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_NEW_GAME_OFFER;
  payload: IChallengeItem | null;
}

interface ISetNewGameOfferedRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_NEW_GAME_OFFERED_REQUEST;
  payload: boolean;
}

interface ISetNewGameOfferedCancelRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_NEW_GAME_OFFERED_CANCEL_REQUEST;
  payload: boolean;
}

interface ISetTopPlayerWarn extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_TOP_PLAYER_WARN;
  payload: boolean;
}

interface ISetBottomPlayerWarn extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_BOTTOM_PLAYER_WARN;
  payload: boolean;
}

interface ISetSoundPlayed extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_SOUND_PLAYED;
  payload: boolean;
}

interface ISetTourGameResult extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_TOUR_GAME_RESULT;
  payload: ITournamentGameTourResult | null;
}

interface ISetGameResultRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_RESULT_REQUEST;
  payload: boolean;
}

interface ISetReadyForArena extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_READY_FOR_ARENA;
  payload: boolean;
}

interface ISetAnalysisPopupOpened extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_IS_ANALYSIS_POPUP_OPENED;
  payload: boolean;
}

interface ISetTimeControl extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_TIME_CONTROL;
  payload: ITimeControl;
}

interface ISetId extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_ID;
  payload: string;
}

interface IResetGame extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.RESET_GAME;
  payload?: undefined;
}

interface ISetReportGamePopupOpened extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_REPORT_GAME_POPUP_OPENED;
  payload: boolean;
}

interface ISetGameReported extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_GAME_REPORTED;
  payload: boolean;
}

interface ISetFiguresView extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_FIGURES_VIEW;
  payload: number;
}

interface ISetShowAnalysis extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_SHOW_ANALYSIS;
  payload: boolean;
}

interface ISetAnalysisItem extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_ANALYSIS_ITEM;
  payload: IChessgunAnalysisItem;
}

interface ISetIsAnalysing extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_IS_ANALYSING;
  payload: boolean;
}

interface ISetEnableUserCommenting extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_ENABLE_USER_COMMENTING;
  payload: boolean;
}

interface ISetMovesClassificationRequest extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_MOVES_CLASSIFICATION_REQUEST;
  payload: boolean;
}

interface ISetMovesClassification extends INGPageActionMessage {
  type: eNGGamePageContextActionTypes.SET_MOVES_CLASSIFICATION;
  payload: IClassifiedMove[];
}

export type INGPageAction =
  | ISetCurrentGame
  | ISetBoardDataRequest
  | ISetBoardData
  | ISetGameZenMode
  | ISetGameReviewMode
  | ISetBoardFlipped
  | ISetEndedAnimationShown
  | ISetDrawOffered
  | ISetDrawOfferSended
  | ISetOpponentOfferedDraw
  | ISetThreefoldRepetition
  | ISetOfferedDrawsCount
  | ISetLastDrawOfferedMoveIndex
  | ISetGamePgn
  | ISetGamePgnName
  | ISetGamePgnRequest
  | ISetNextGameStatus
  | ISetMovesHistory
  | ISetMovesHistoryLength
  | ISetLastMove
  | ISetSelectedMove
  | ISetWhiteCaptured
  | ISetWhiteAdvantage
  | ISetBlackCaptured
  | ISetBlackAdvantage
  | ISetTopWarnOpacity
  | ISetTBottomWarnOpacity
  | ISetAnimationTime
  | ISetGameEnded
  | ISetIsGameDataLoaded
  | ISetCaloriesLoss
  | ISetRatingChange
  | ISetNewRating
  | ISetRematchOffer
  | ISetRematchOfferedRequest
  | ISetRematchOfferedCancelRequest
  | ISetNewGameOffer
  | ISetNewGameOfferedRequest
  | ISetNewGameOfferedCancelRequest
  | ISetTopPlayerWarn
  | ISetBottomPlayerWarn
  | ISetSoundPlayed
  | ISetTourGameResult
  | ISetGameResultRequest
  | ISetReadyForArena
  | ISetAnalysisPopupOpened
  | ISetTimeControl
  | ISetId
  | IResetGame
  | ISetReportGamePopupOpened
  | ISetGameReported
  | ISetFiguresView
  | ISetInviteChallenge
  | ISetInviteChallengeRequest
  | ISetInviteChallengeAcceptRequest
  | ISetInviteChallengeDeclineRequest
  | ISetShowAnalysis
  | ISetAnalysisItem
  | ISetIsAnalysing
  | ISetEnableUserCommenting
  | ISetMovesClassificationRequest
  | ISetMovesClassification;
