import { moveTypes } from "../../../core";
import { getMoveInfo } from "../../../core/move";
import { getCastlingMovePiece } from "./getCastlingMovePiece";
import { getNormalMovePiece } from "./getNormalMovePiece";
import { getPawnMovePiece } from "./getPawnMovePiece";
export const getUpdatedPieces = ({ prevFen, fen, lan, pieces, }) => {
    const { moveData, checkmateData: { check, checkmate, kingIndex }, } = getMoveInfo(prevFen, lan);
    const $pieces = pieces.map((piece) => {
        if (moveData.type === moveTypes.PAWN)
            return getPawnMovePiece(moveData, piece);
        if (moveData.type === moveTypes.CASTLING)
            return getCastlingMovePiece(moveData, piece);
        return getNormalMovePiece(moveData, piece);
    });
    return $pieces.map((piece) => {
        const item = Object.assign(Object.assign({}, piece), { fen, check: check && piece.position === kingIndex, checkmate: checkmate && piece.position === kingIndex, history: undefined });
        return Object.assign(Object.assign({}, item), { history: [...piece.history, item] });
    });
};
