import { IAccuracyPerformance, IMovesClassification } from '@types';
import { BaseService } from './_base.service';
import { httpInstance } from '.';

export class AnalysisService extends BaseService {
  /**
   * Осуществляет запрос о получении дебюта текущего fen.
   * @param {string} boardId - id доски
   */
  getAccuracyPerformance(boardId: string) {
    return this.ajax.get<IAccuracyPerformance>(
      `analysis/batch-analysis/${boardId}/`
    );
  }

  /**
   * Осуществляет запрос о получении списка ходов для анализа.
   * @param {string} boardId - id доски
   */
  getMovesClassification(boardId: string) {
    return this.ajax.get<IMovesClassification>(
      `analysis/moves-classification/${boardId}/`
    );
  }
}

export const analysisService = new AnalysisService({ instance: httpInstance });
