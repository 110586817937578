import { chessSide, DEFAULT_POSITION } from "../../../core";
import { parseFen } from "../../../core/fen/_parseFen";
import { getUpdatedPieces } from "./getUpdatedPieces";
export const createFigures = (fen = DEFAULT_POSITION) => {
    const { figures, checkmate: { check, checkmate, kingIndex }, } = parseFen(fen);
    return figures.map((parsedFig) => {
        const item = {
            id: "id" + Math.random().toString(16).slice(2),
            fen,
            position: parsedFig.position,
            bgTag: parsedFig.type + (parsedFig.color === chessSide.WHITE ? "w" : "b"),
            type: parsedFig.type,
            color: parsedFig.color,
            check: check && kingIndex === parsedFig.position,
            checkmate: checkmate && kingIndex === parsedFig.position,
        };
        return Object.assign(Object.assign({}, item), { history: [item] });
    });
};
const createPiecesWithHistory = (history) => {
    const firstItem = history[0];
    let figures = createFigures(firstItem === null || firstItem === void 0 ? void 0 : firstItem.fen);
    history.forEach((item, i) => {
        var _a;
        if (i > 0)
            figures = getUpdatedPieces({
                prevFen: (_a = history[i - 1]) === null || _a === void 0 ? void 0 : _a.fen,
                fen: item.fen,
                lan: item.lan,
                pieces: figures,
            });
    });
    return figures;
};
export const createPieces = (history, withPieceHistory) => {
    if (withPieceHistory)
        return createPiecesWithHistory(history);
    const lastItem = history[history.length - 1];
    return createFigures(lastItem === null || lastItem === void 0 ? void 0 : lastItem.fen);
};
