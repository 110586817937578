import isMobile from '@chessarena/components/lib/utils/isMobile';

export class Engine {
  readonly stockfish?: Worker;
  readonly onMessage: (callback: (line: string) => void) => void = () => {};
  debug = false;
  waitReady = false;
  movetime = isMobile() ? 30000 : 60000;
  fen = '';
  depth = 24;

  constructor(debug: boolean) {
    if (typeof window === 'undefined') return;

    this.debug = debug;
    this.stockfish = new Worker(
      window.crossOriginIsolated
        ? '/engines/stockfish-16.1/stockfish-16.1-lite.js'
        : '/engines/stockfish-16/stockfish-nnue-16-single.js'
    );

    this.onMessage = (callback) => {
      if (!this.stockfish) return;
      this.stockfish.onmessage = (event) => {
        const line = event && typeof event === 'object' ? event.data : event;
        if (this.debug) console.log('line', line, 'readyok =', !this.waitReady);

        if (line === 'readyok') {
          this.waitReady = false;
          this.go();
          return;
        }

        if (this.waitReady) return;

        callback(line);
      };
    };

    this.stockfish.postMessage('uci');
    this.stockfish.postMessage(`setoption name UCI_AnalyseMode value true`);
  }

  evaluatePosition = (fen: string, depth = 24) => {
    if (!this.stockfish) return;

    this.fen = fen;
    this.depth = depth;

    if (this.debug) console.log('evaluatePosition ucinewgame', fen, depth);

    if (!this.waitReady) {
      this.stockfish.postMessage('stop');
      this.stockfish.postMessage('ucinewgame');
      this.stockfish.postMessage('isready');
      this.waitReady = true;
    }
  };

  go = () => {
    if (!this.stockfish) return;
    this.stockfish.postMessage(`position fen ${this.fen}`);
    this.stockfish.postMessage(
      `go depth ${this.depth} movetime ${this.movetime}`
    );
  };

  setMultiPV(multiPv = 1) {
    if (!this.stockfish) return;
    this.stockfish.postMessage(`setoption name MultiPV value ${multiPv}`);
  }

  stop() {
    if (!this.stockfish) return;
    this.stockfish.postMessage('stop');
  }

  quit() {
    if (!this.stockfish) return;
    this.stockfish.postMessage('quit');
  }
}
