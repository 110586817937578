import { useEffect, useState } from 'react';

import { IChessgunState, IChessgunStore } from 'chessgun/lib/core';

export const useChessgunStore = <K extends keyof IChessgunState>(
  store: IChessgunStore | null | undefined,
  key: K
): IChessgunState[K] | undefined => {
  const [state, setState] = useState<IChessgunState[K] | undefined>(
    store?.get(key)
  );

  useEffect(() => {
    return store?.on(key, (value) => {
      setState(value);
    });
  }, [key, store]);

  return state;
};
