import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import { getMyPendingDraws } from '@utils/_ng_helpers';
import { chessSide } from 'chessgun/core';
import { useEffect } from 'react';
import { useNGPageActions } from '../_actions';

export const useDrawOffers = (
  currentGame: ILobbyContextGame | null,
  myColor: chessSide,
  actions: Pick<
    ReturnType<typeof useNGPageActions>,
    'setOfferedDrawsCount' | 'setLastDrawOfferedMoveIndex'
  >
) => {
  // восстановить количество ничьих при перезагрузке страницы
  // восстановить последний ход ничьи при перезагрузке страницы
  useEffect(() => {
    const drawOffers =
      currentGame?.state?.playingPh?.drawOffers?.filter((item) => {
        const isMyColorWhite = myColor === chessSide.WHITE;
        return item.start.clr === isMyColorWhite;
      }) ?? [];

    const drawOffersCount = drawOffers.length;
    const myPendingDraws = getMyPendingDraws(currentGame, myColor);

    const lastMyOffer = drawOffers
      ?.filter((item) => {
        const isMyColorWhite = myColor === chessSide.WHITE;
        return item.start.clr === isMyColorWhite;
      })
      ?.at(-1);

    if (drawOffersCount || myPendingDraws) {
      actions.setOfferedDrawsCount(drawOffersCount + myPendingDraws);
    }

    if (lastMyOffer) {
      actions.setLastDrawOfferedMoveIndex(lastMyOffer.start.turnAbsNum);
    }
  }, [currentGame?.state?.playingPh?.drawOffers?.length, myColor]);
};
