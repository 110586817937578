import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import {
  Chessgun,
  IChessgunAnalysisItem,
  IChessgunAnalysisItemProps,
} from 'chessgun/core';
import { IMoveAnalysisItem } from '@types';
import { Engine } from '@utils/_engine';

import { parseAnalysisItem } from 'chessgun/core/analysis/_parseAnalysisItem';

export const useEvaluatePosition = ({
  enabled,
  currentFen,
  params,
  engine: boardEngine,
  onAnalyse,
  onSetAnalysing,
}: {
  enabled?: boolean;
  engine?: Chessgun | null;
  currentFen?: string;
  params?: { depth?: number; multiPv?: number };
  onAnalyse: (data: IChessgunAnalysisItem) => void;
  onSetAnalysing?: (value: boolean) => void;
}) => {
  const { depth = 40, multiPv = 1 } = params || {};
  const router = useRouter();
  const debug = useMemo(() => router.query.debug === 'true', [router]);

  const [engine, setEngine] = useState<Engine | null>(null);

  useEffect(() => {
    if (enabled && !engine) setEngine(new Engine(debug));
    if (!enabled && engine) engine.stop();
  }, [debug, engine, enabled]);

  useEffect(() => {
    return () => {
      if (engine) engine.quit();
    };
  }, [engine]);

  useEffect(() => {
    if (engine) engine.setMultiPV(multiPv);
  }, [engine, multiPv]);

  const onEvaluate = useCallback(
    async (item: IChessgunAnalysisItemProps) => {
      try {
        const analysis = await parseAnalysisItem(item, 13);
        if (onAnalyse) onAnalyse(analysis);
        if (!!boardEngine) boardEngine.addAnalysisItem(item);
      } catch (e) {
        console.log('PARSE ANALYSIS ERROR', e);
      }
    },
    [currentFen, boardEngine, onAnalyse]
  );

  const evaluatePosition = useCallback(
    (fen: string, depth = 40) => {
      if (!engine) return;
      if (onSetAnalysing) onSetAnalysing(true);

      const multipv: IMoveAnalysisItem[] = [];
      let $depth = 0;

      for (let i = 0; i < multiPv; i += 1) {
        multipv.push({} as IMoveAnalysisItem);
      }

      engine.evaluatePosition(fen, depth);
      engine.onMessage((line: string) => {
        const part = line.split(' ');

        const moves = [];
        let score = 0;
        let $multiPv = 1;
        let mate = null;
        let isPv = false;

        const [, turn] = fen.split(' ');

        for (let i = 0; i < part.length; i += 1) {
          if (part[i] === 'score') {
            if (part[i + 1] === 'cp') score = parseInt(part[i + 2], 10);
            if (part[i + 1] === 'mate') mate = parseInt(part[i + 2], 10);
          }
          if (part[i] === 'multipv') {
            $multiPv = parseInt(part[i + 1], 10);
          }
          if (isPv && part[i] !== 'bmc') moves.push(part[i]);
          if (part[i] === 'pv') isPv = true;
          if (part[i] === 'bestmove') isPv = true;
          if (part[i] === 'bmc') isPv = false;
          if (part[i] === 'depth') $depth = parseInt(part[i + 1], 10);
        }

        if (turn === 'b') score *= -1;
        if (!part.includes('bestmove')) {
          const pvn = $multiPv - 1;
          multipv[pvn].line_eval = score;
          multipv[pvn].mate_in = mate;
          multipv[pvn].line = moves;
        }

        const checkmate = line === 'info depth 0 score mate 0';
        const stalemate = line === 'info depth 0 score cp 0';

        const canPost =
          (($depth > 10 && part.includes('multipv')) ||
            checkmate ||
            stalemate) &&
          !part.includes('lowerbound') &&
          !part.includes('upperbound') &&
          !part.includes('currmove');

        if (onEvaluate && canPost)
          onEvaluate(
            JSON.parse(JSON.stringify({ fen, depth: $depth, multipv }))
          );
      });
    },
    [engine, onEvaluate]
  );

  useEffect(() => {
    if (enabled && currentFen && engine) evaluatePosition(currentFen, depth);
  }, [enabled, currentFen, depth, engine]);
};
