import { useCallback } from 'react';
import {
  Chessgun,
  chessSide,
  figureType,
  IChessgunAnalysisItem,
  IChessgunHistoryItem,
} from 'chessgun/core';
import {
  eChessColor,
  GameRatingMode,
  IChallengeItem,
  IGamePlayer,
  ITimeControl,
  ITourGameData,
  TournamentStatus,
  TournamentType,
} from '@types';
import { httpStatuses } from '@constants';

import {
  IInviteChallengeRequestParams,
  IRequestError,
  IShortChallengeRequestParams,
  ITournamentGameTourResult,
  lsKeys,
} from '@types';
import {
  eNGGamePageContextActionTypes,
  INGPageContextDispatch,
  INGPageContextState,
} from './_types';
import { eNextGameStatus, ICurrentSettings, TNextGameStatus } from '../_types';
import { useAuthStore, useUserDataStore } from '@store/storeshed';
import { getCookie } from '@utils/_cookies';
import { gameService } from '@services/_game.service';
import { tournamentService } from '@services/_tournament.service';
import { tournamentGameService } from '@services/_tournament_game.service';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import { GameWSController } from '@store/context/lobby_context/ws';
import { eGameServerPayloadType } from '@store/context/lobby_context/ws/_types';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';
import { challengesService } from '@services/_challenges.service';
import { IAlertPopup } from '@utils/hooks/_useAlertPopup.hook';
import { analysisService } from '@services/_analysis.service';

export const useNGPageActions = (
  dispatch: INGPageContextDispatch,
  state: INGPageContextState,
  alertPopup: IAlertPopup
) => {
  const token = useAuthStore('token');
  const uid = useAuthStore('uid');
  const userData = useUserDataStore('data');

  const {
    actions: { updateGameRequestState },
  } = useLobbyContext();

  const getGamePgn = useCallback(
    async (boardId: string, pgnFileName: string) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_PGN_REQUEST,
        payload: true,
      });
      try {
        const { data } = await gameService.getGamePgn(boardId);

        dispatch({
          type: eNGGamePageContextActionTypes.SET_GAME_PGN_NAME,
          payload: pgnFileName,
        });
        dispatch({
          type: eNGGamePageContextActionTypes.SET_GAME_PGN,
          payload: data,
        });
      } catch (err) {
        console.log(err);
      }

      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_PGN_REQUEST,
        payload: false,
      });
    },
    [dispatch]
  );

  const getBoardResult = useCallback(
    async (boardId: string) => {
      const jwt = getCookie(lsKeys.JWT);

      /** Не выполнять если у нас еще не прогрузилась инфа о юзере */
      if (jwt && !uid) {
        return;
      }

      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_RESULT_REQUEST,
        payload: true,
      });

      try {
        const { data } = await gameService.getBoardResult(boardId);

        const isWhitePlayer = uid === data.white_uid;
        const caloriesLoss = isWhitePlayer
          ? data.white_calories_loss
          : data.black_calories_loss;
        setCaloriesLoss(caloriesLoss);

        const ratingChange = isWhitePlayer
          ? data.white_delta
          : data.black_delta;

        setRatingChange(ratingChange);

        const newRating = isWhitePlayer
          ? data.white_elo_result
          : data.black_elo_result;
        setNewRating(newRating);
      } catch (err) {
        console.log(err);
      }

      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_RESULT_REQUEST,
        payload: false,
      });
    },
    [uid]
  );

  // TODO: рефактор
  const getTourBoardResult = useCallback(
    async (boardData: ITourGameData | null, isWhitePlayer: boolean) => {
      if (!boardData) return;

      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_RESULT_REQUEST,
        payload: true,
      });

      const isArena = boardData.tournament.kind === TournamentType.ARENA;
      const isLastRound =
        boardData?.tour_v2.number === boardData.tournament.tours_count &&
        !isArena;
      if (
        boardData.tournament.status === TournamentStatus.GOES &&
        !isLastRound
      ) {
        setNextGameStatus({
          status: isArena
            ? eNextGameStatus.SEARCH_NEW_ARENA_GAME
            : eNextGameStatus.WAITING_NEXT_OPPONENT,
        });
      }

      try {
        const { data } = await tournamentGameService.getTournamentGameResult(
          boardData.board_id
        );

        setTourGameResult(data);

        const caloriesLoss = isWhitePlayer
          ? data.white_calories_loss
          : data.black_calories_loss;
        setCaloriesLoss(caloriesLoss);
      } catch (err) {
        console.log(err);
      }

      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_RESULT_REQUEST,
        payload: false,
      });
    },
    []
  );

  const getReadyForArena = useCallback(async (tournamentId: number) => {
    try {
      const { data } = await tournamentService.getTournamentStandingYou(
        tournamentId
      );

      if (data.you?.ready_for_arena) {
        setReadyForArena(data.you.ready_for_arena);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const resumeArenaTournament = useCallback(
    async (tournamentId: number | string) => {
      try {
        const { ok } = await tournamentService.resumeTournament(tournamentId);

        if (ok) {
          setReadyForArena(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );
  const pauseArenaTournament = useCallback(
    async (tournamentId: number | string) => {
      try {
        const { ok } = await tournamentService.pauseTournament(tournamentId);

        if (ok) {
          setReadyForArena(false);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const setGameZenMode = useCallback(
    (isZenMode: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_ZEN_MODE,
        payload: isZenMode,
      });
    },
    [dispatch]
  );

  const setGameReviewMode = useCallback(
    (isReviewMode: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_REVIEW_MODE,
        payload: isReviewMode,
      });
    },
    [dispatch]
  );

  const setBoardFlipped = useCallback(
    (boardFlipped: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_BOARD_FLIPPED,
        payload: boardFlipped,
      });
    },
    [dispatch]
  );

  const setEndedAnimationShown = useCallback(
    (shown: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_ENDED_ANIMATION_SHOWN,
        payload: shown,
      });
    },
    [dispatch]
  );

  const setDrawOffered = useCallback(
    (offered: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_DRAW_OFFERED,
        payload: offered,
      });
    },
    [dispatch]
  );
  const setDrawOfferSended = useCallback(
    (offerSended: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_DRAW_OFFER_SENDED,
        payload: offerSended,
      });
    },
    [dispatch]
  );
  const setOpponentOfferedDraw = useCallback(
    (opponentOfferedDraw: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_OPPONENT_OFFERED_DRAW,
        payload: opponentOfferedDraw,
      });
    },
    [dispatch]
  );
  const setThreefoldRepetition = useCallback(
    (threefoldRepetition: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_THREEFOLD_REPETITION,
        payload: threefoldRepetition,
      });
    },
    [dispatch]
  );
  const setOfferedDrawsCount = useCallback(
    (count: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_OFFERED_DRAWS_COUNT,
        payload: count,
      });
    },
    [dispatch]
  );
  const setLastDrawOfferedMoveIndex = useCallback(
    (index: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_LAST_DRAW_OFFERED_MOVE_INDEX,
        payload: index,
      });
    },
    [dispatch]
  );

  const setNextGameStatus = useCallback(
    (status: TNextGameStatus | null) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_NEXT_GAME_STATUS,
        payload: status,
      });
    },
    [dispatch]
  );

  const setMovesHistory = useCallback(
    (history: (IChessgunHistoryItem | null)[]) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_MOVES_HISTORY,
        payload: history,
      });
    },
    [dispatch]
  );
  const setMovesHistoryLength = useCallback(
    (historyLength: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_MOVES_HISTORY_LENGTH,
        payload: historyLength,
      });
    },
    [dispatch]
  );

  const setLastMove = useCallback(
    (lastMove: IChessgunHistoryItem | null) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_LAST_MOVE,
        payload: lastMove,
      });
    },
    [dispatch]
  );
  const setSelectedMove = useCallback(
    (selectedMove: IChessgunHistoryItem | null) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_SELECTED_MOVE,
        payload: selectedMove,
      });
    },
    [dispatch]
  );

  const setWhiteCaptured = useCallback(
    (figures: figureType[]) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_WHITE_CAPTURED,
        payload: figures,
      });
    },
    [dispatch]
  );
  const setWhiteAdvantage = useCallback(
    (advantage: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_WHITE_ADVANTAGE,
        payload: advantage,
      });
    },
    [dispatch]
  );
  const setBlackCaptured = useCallback(
    (figures: figureType[]) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_BLACK_CAPTURED,
        payload: figures,
      });
    },
    [dispatch]
  );
  const setBlackAdvantage = useCallback(
    (advantage: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_BLACK_ADVANTAGE,
        payload: advantage,
      });
    },
    [dispatch]
  );

  const setTopWarnOpacity = useCallback(
    (opacity: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_TOP_WARN_OPACITY,
        payload: opacity,
      });
    },
    [dispatch]
  );
  const setBottomWarnOpacity = useCallback(
    (opacity: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_BOTTOM_WARN_OPACITY,
        payload: opacity,
      });
    },
    [dispatch]
  );

  const setAnimationTime = useCallback(
    (animationTime: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_ANIMATION_TIME,
        payload: animationTime,
      });
    },
    [dispatch]
  );

  const setGameEnded = useCallback(
    (ended: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_ENDED,
        payload: ended,
      });
    },
    [dispatch]
  );

  const setIsGameDataLoaded = useCallback(
    (isGameDataLoaded: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_DATA_LOADED,
        payload: isGameDataLoaded,
      });
    },
    [dispatch]
  );

  const setCaloriesLoss = useCallback(
    (caloriesLoss: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_CALORIES_LOSS,
        payload: caloriesLoss,
      });
    },
    [dispatch]
  );
  const setRatingChange = useCallback(
    (ratingChange: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_RATING_CHANGE,
        payload: ratingChange,
      });
    },
    [dispatch]
  );
  const setNewRating = useCallback(
    (ratingChange: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_NEW_RATING,
        payload: ratingChange,
      });
    },
    [dispatch]
  );

  const setInviteChallenge = useCallback(
    (challenge: IChallengeItem | null) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE,
        payload: challenge,
      });
    },
    [dispatch]
  );

  const setInviteChallengeRequest = useCallback(
    (value: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_REQUEST,
        payload: value,
      });
    },
    [dispatch]
  );

  const setRematchOffer = useCallback(
    (offer: IChallengeItem | null) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_REMATCH_OFFER,
        payload: offer,
      });
    },
    [dispatch]
  );

  const setRematchOfferedRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_REMATCH_OFFERED_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setRematchOfferedCancelRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_REMATCH_OFFERED_CANCEL_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setInviteChallengeAcceptRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_ACCEPT_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setInviteChallengeDeclineRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_DECLINE_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setNewGameOffer = useCallback(
    (offer: IChallengeItem | null) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_NEW_GAME_OFFER,
        payload: offer,
      });
    },
    [dispatch]
  );

  const setNewGameOfferedRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_NEW_GAME_OFFERED_CANCEL_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setNewGameOfferedCancelRequest = useCallback(
    (inRequest: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_NEW_GAME_OFFERED_CANCEL_REQUEST,
        payload: inRequest,
      });
    },
    [dispatch]
  );

  const setTopPlayerWarn = useCallback(
    (warn: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_TOP_PLAYER_WARN,
        payload: warn,
      });
    },
    [dispatch]
  );
  const setBottomPlayerWarn = useCallback(
    (warn: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_BOTTOM_PLAYER_WARN,
        payload: warn,
      });
    },
    [dispatch]
  );

  const setSoundPlayed = useCallback(
    (sound: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_SOUND_PLAYED,
        payload: sound,
      });
    },
    [dispatch]
  );

  const setTourGameResult = useCallback(
    (result: ITournamentGameTourResult | null) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_TOUR_GAME_RESULT,
        payload: result,
      });
    },
    [dispatch]
  );

  const setReadyForArena = useCallback(
    (readyForArena: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_READY_FOR_ARENA,
        payload: readyForArena,
      });
    },
    [dispatch]
  );

  const setIsAnalysisPopupOpened = useCallback(
    (isOpened: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_IS_ANALYSIS_POPUP_OPENED,
        payload: isOpened,
      });
    },
    [dispatch]
  );

  const setReportGamePopupOpened = useCallback(
    (opened: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_REPORT_GAME_POPUP_OPENED,
        payload: opened,
      });
    },
    [dispatch]
  );

  const setGameReported = useCallback(
    (opened: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_GAME_REPORTED,
        payload: opened,
      });
    },
    [dispatch]
  );

  const setFiguresView = useCallback(
    (value: number) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_FIGURES_VIEW,
        payload: value,
      });
    },
    [dispatch]
  );

  const setId = useCallback(
    (id: string) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_ID,
        payload: id,
      });
    },
    [dispatch]
  );

  const setTimeControl = useCallback(
    (timeControl: ITimeControl) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_TIME_CONTROL,
        payload: timeControl,
      });
    },
    [dispatch]
  );

  const resetGame = useCallback(() => {
    dispatch({
      type: eNGGamePageContextActionTypes.RESET_GAME,
    });
  }, [dispatch]);

  const setShowAnalysis = useCallback(
    (data: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_SHOW_ANALYSIS,
        payload: data,
      });
    },
    [dispatch]
  );

  const setAnalysisItem = useCallback(
    (data: IChessgunAnalysisItem) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_ANALYSIS_ITEM,
        payload: data,
      });
    },
    [dispatch]
  );

  const setIsAnalysing = useCallback(
    (value: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_IS_ANALYSING,
        payload: value,
      });
    },
    [dispatch]
  );

  const setEnableUserCommenting = useCallback(
    (value: boolean) => {
      dispatch({
        type: eNGGamePageContextActionTypes.SET_ENABLE_USER_COMMENTING,
        payload: value,
      });
    },
    [dispatch]
  );

  const toggleAnalysis = useCallback(() => {
    setShowAnalysis(!state.showAnalysis);
  }, [setShowAnalysis, state.showAnalysis]);

  const onAbortClick = useCallback((ws: GameWSController | undefined) => {
    if (ws) {
      ws.send(eGameServerPayloadType.ABORT);
    }
  }, []);

  const resign = useCallback((ws: GameWSController | undefined) => {
    if (!ws) return;

    ws.send(eGameServerPayloadType.RESIGN);
  }, []);

  const acceptDraw = (currentGame: ILobbyContextGame | null) => {
    if (!currentGame) return;
    currentGame.ws.send(eGameServerPayloadType.DRAW_OFFER);
  };

  const onDrawClick = useCallback(
    (currentGame: ILobbyContextGame | null) => {
      setDrawOffered(true);
      currentGame?.ws.send(eGameServerPayloadType.DRAW_OFFER);
      setDrawOfferSended(true);
    },
    [setDrawOfferSended, setDrawOffered]
  );

  const onCancelDrawClick = useCallback(() => {
    setDrawOffered(false);
    setDrawOfferSended(false);
  }, [setDrawOffered, setDrawOfferSended]);

  const onCloseClick = useCallback(
    (chessgun: Chessgun | null) => {
      const movesHistory = state.movesHistory;
      const lastMove = movesHistory[movesHistory.length - 1];
      setSelectedMove(lastMove);
      setGameReviewMode(false);

      if (lastMove && chessgun) {
        chessgun.setCurrentMove(lastMove.fen);
      }
    },
    [setSelectedMove, setGameReviewMode, state.movesHistory]
  );
  const onSelectMove = useCallback(
    (fen: string) => {
      const movesHistory = state.movesHistory;
      const selectedMove = state.selectedMove;

      const moveIndex = movesHistory.findIndex((move) => move?.fen === fen);
      const move = movesHistory[moveIndex];
      const lastMove = state.lastMove;
      const isLastMoveSelected = move?.fen !== lastMove?.fen;

      if (move && move.fen !== selectedMove?.fen) {
        setSelectedMove(move);
        setGameReviewMode(isLastMoveSelected);
      }
    },
    [setSelectedMove, setGameReviewMode, state.movesHistory, state.selectedMove]
  );
  const onArrowClick = useCallback(
    (move: IChessgunHistoryItem | null) => {
      const lastMove = state.lastMove;
      const isLastMoveSelected = move?.fen !== lastMove?.fen;
      // const gameEnded = state.gameEnded;

      setSelectedMove(move);

      if (!move || !lastMove) return;

      setGameReviewMode(isLastMoveSelected);
      // if (move.fen !== lastMove.fen) {
      //   setGameReviewMode(true);
      // } else {
      //   if (!gameEnded) {
      //   setGameReviewMode(false);
      //   }
      // }
    },
    [setSelectedMove, setGameReviewMode, state.lastMove]
  );
  const onMoveChange = useCallback(
    (move: IChessgunHistoryItem | null) => {
      setSelectedMove(move);
    },
    [setSelectedMove]
  );

  const onCancelRematch = useCallback(
    async (inviteId: number | null) => {
      if (!inviteId) return;

      setRematchOfferedCancelRequest(true);
      try {
        const { status } = await challengesService.cancelInviteChallenge(
          inviteId,
          uid,
          token
        );

        if (status === httpStatuses.DELETED) {
          setRematchOffer(null);
        }
      } catch (error) {
        console.log(error);
        const e = error as IRequestError<{ code?: string; detail: string }>;
        if (e.data?.detail) alertPopup.showAlertPopup(e.data.detail);
        if (
          ['challenge_not_found', 'owner_disconnected'].includes(
            e.data?.code || ''
          )
        )
          setInviteChallenge(null);
      }
      setRematchOfferedCancelRequest(false);
    },
    [setRematchOfferedCancelRequest, uid, token, setRematchOffer]
  );

  const onAcceptRematch = useCallback(
    async (inviteCode: string) => {
      if (!uid) return;

      try {
        setInviteChallengeAcceptRequest(true);
        await challengesService.acceptInviteChallenge(inviteCode, uid, token);
        setInviteChallenge(null);
      } catch (error) {
        console.log(error);
        const e = error as IRequestError<{ code?: string; detail: string }>;
        if (e.data?.detail) alertPopup.showAlertPopup(e.data.detail);
        if (
          ['challenge_not_found', 'owner_disconnected'].includes(
            e.data?.code || ''
          )
        )
          setInviteChallenge(null);
        updateGameRequestState(null);
      } finally {
        setInviteChallengeAcceptRequest(false);
      }
    },
    [uid, token, setInviteChallengeAcceptRequest]
  );

  const onDeclineRematch = useCallback(
    async (inviteCode: string) => {
      if (!uid) return;

      try {
        setInviteChallengeDeclineRequest(true);
        await challengesService.declineInviteChallenge(inviteCode);
        setInviteChallenge(null);
      } catch (error) {
        console.log(error);
        const e = error as IRequestError<{ code?: string; detail: string }>;
        if (e.data?.detail) alertPopup.showAlertPopup(e.data.detail);
        if (
          ['challenge_not_found', 'owner_disconnected'].includes(
            e.data?.code || ''
          )
        )
          setInviteChallenge(null);
      } finally {
        setInviteChallengeDeclineRequest(false);
      }
    },
    [uid, setInviteChallengeDeclineRequest, setInviteChallenge]
  );

  const onSendRematch = useCallback(
    async (
      opponent: IGamePlayer | null,
      desiredColor: chessSide,
      timeControl?: ITimeControl | null,
      ratingType?: GameRatingMode
    ) => {
      if (!uid || !timeControl || !opponent) return;

      const colors = {
        [chessSide.WHITE]: eChessColor.w,
        [chessSide.BLACK]: eChessColor.b,
      } as const;

      try {
        setRematchOfferedRequest(true);

        const params: IInviteChallengeRequestParams = {
          desiredColor: colors[desiredColor],
          withOpponentUid: opponent.uid,
          timeControlId: timeControl.id,
          ratingType,
        };

        const { data } = await challengesService.createInviteChallenge(
          params,
          uid,
          token
        );
        setRematchOffer(data);

        gtmPush({
          event: GTM_EVENTS.START_GAME,
          paramUserId: userData?.player.player_id,
        });
      } catch (error) {
        console.log(error);
        const e = error as IRequestError<{ code?: string; detail: string }>;
        if (e.data?.detail) alertPopup.showAlertPopup(e.data.detail);
      } finally {
        setRematchOfferedRequest(false);
      }
    },
    [uid, userData, token, setRematchOffer, setRematchOfferedRequest]
  );

  const getIncomingInviteChallenge = useCallback(
    async (boardData: ITourGameData) => {
      try {
        setInviteChallengeRequest(true);
        const opponent =
          boardData.white_player.uid === uid
            ? boardData.black_player
            : boardData.white_player;
        const params = {
          owner_anonymous_uid: !token ? uid : undefined,
          opponent_uid: opponent.uid,
          time_control_id: boardData.time_control?.id,
        };
        const { data } = await challengesService.getIncomingInviteChallenges(
          params
        );
        if (data.results?.length) {
          setInviteChallenge(data.results[0] as IChallengeItem);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setInviteChallengeRequest(false);
      }
    },
    [setInviteChallengeRequest, uid, token, setInviteChallenge]
  );

  const getOutcomingInviteChallenge = useCallback(
    async (boardData: ITourGameData) => {
      try {
        setRematchOfferedRequest(true);
        const opponent =
          boardData.white_player.uid === uid
            ? boardData.black_player
            : boardData.white_player;
        const params = {
          owner_anonymous_uid: !token ? uid : undefined,
          opponent_uid: opponent.uid,
          time_control_id: boardData.time_control?.id,
        };
        const { data } = await challengesService.getOutcomingInviteChallenges(
          params
        );
        if (data.results?.length) {
          setRematchOffer(data.results[0] as IChallengeItem);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setRematchOfferedRequest(false);
      }
    },
    [setRematchOfferedRequest, uid, token, setRematchOffer]
  );

  const onCreateNewGameInviteByYourCurrentSettings = useCallback(
    async (currentSettings: ICurrentSettings | null) => {
      if (!uid || !currentSettings) return;

      const { rating, timeControl } = currentSettings;

      try {
        setNewGameOfferedRequest(true);

        const params: IShortChallengeRequestParams = {
          ratingType: rating ? rating : GameRatingMode.UNRATED,
          timeControlId: timeControl.id,
        };

        const { data } = await challengesService.createShortChallenge(
          params,
          uid,
          token
        );
        setNewGameOffer(data);

        gtmPush({
          event: GTM_EVENTS.START_GAME,
          paramUserId: userData?.player.player_id,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setNewGameOfferedRequest(false);
      }
    },
    [uid, userData, token, setNewGameOffer, setNewGameOfferedRequest]
  );

  const onCancelNewGameInvite = useCallback(
    async (inviteId: number) => {
      try {
        setNewGameOfferedCancelRequest(true);
        const { status } = await challengesService.cancelShortChallenge(
          inviteId,
          uid,
          token
        );

        if (status === httpStatuses.DELETED) {
          setNewGameOffer(null);
        }
        setNewGameOfferedCancelRequest(false);
      } catch (error) {
        console.log(error);
      }
    },
    [uid, token, setNewGameOffer, setNewGameOfferedCancelRequest]
  );

  const getMovesClassification = async (boardId: string) => {
    dispatch({
      type: eNGGamePageContextActionTypes.SET_MOVES_CLASSIFICATION_REQUEST,
      payload: true,
    });

    try {
      const { data } = await analysisService.getMovesClassification(boardId);

      dispatch({
        type: eNGGamePageContextActionTypes.SET_MOVES_CLASSIFICATION,
        payload: data.moves,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch({
      type: eNGGamePageContextActionTypes.SET_MOVES_CLASSIFICATION_REQUEST,
      payload: false,
    });
  };

  return {
    getGamePgn,
    getBoardResult,
    getTourBoardResult,
    getReadyForArena,
    resumeArenaTournament,
    pauseArenaTournament,

    setGameZenMode,
    setGameReviewMode,
    setBoardFlipped,
    setEndedAnimationShown,
    setDrawOffered,
    setDrawOfferSended,
    setOpponentOfferedDraw,
    setThreefoldRepetition,
    setOfferedDrawsCount,
    setLastDrawOfferedMoveIndex,
    setNextGameStatus,
    setMovesHistory,
    setMovesHistoryLength,
    setLastMove,
    setSelectedMove,
    setWhiteCaptured,
    setWhiteAdvantage,
    setBlackCaptured,
    setBlackAdvantage,
    setTopWarnOpacity,
    setBottomWarnOpacity,
    setAnimationTime,
    setGameEnded,
    setIsGameDataLoaded,
    setRematchOffer,
    setNewGameOffer,
    setTopPlayerWarn,
    setBottomPlayerWarn,
    setSoundPlayed,
    setIsAnalysisPopupOpened,
    setReportGamePopupOpened,
    setGameReported,
    setFiguresView,
    setTimeControl,
    setId,
    setInviteChallenge,
    setInviteChallengeRequest,
    resetGame,
    setShowAnalysis,
    toggleAnalysis,
    setAnalysisItem,
    setIsAnalysing,
    setEnableUserCommenting,

    onAbortClick,
    resign,
    acceptDraw,
    onDrawClick,
    onCancelDrawClick,
    onCloseClick,
    onSelectMove,
    onArrowClick,
    onMoveChange,
    getIncomingInviteChallenge,
    getOutcomingInviteChallenge,
    onCancelRematch,
    onAcceptRematch,
    onDeclineRematch,
    onSendRematch,
    onCreateNewGameInviteByYourCurrentSettings,
    onCancelNewGameInvite,
    getMovesClassification,
  };
};
