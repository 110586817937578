export class IDBStore {
  static instance: IDBStore | null;
  readonly storeName: string;
  readonly dbp: Promise<IDBDatabase>;

  constructor(dbName = 'chessarena', storeName = 'games') {
    this.storeName = storeName;
    this.dbp = new Promise((resolve, reject) => {
      const openreq = indexedDB.open(dbName, 1); /* eslint-disable-line */
      openreq.onerror = () => reject(openreq.error);
      openreq.onsuccess = () => resolve(openreq.result);
      openreq.onupgradeneeded = () => {
        openreq.result.createObjectStore(storeName);
      };
    });
  }

  static getInstance(dbName?: string, storeName?: string) {
    if (IDBStore.instance) return IDBStore.instance;
    IDBStore.instance = new IDBStore(dbName, storeName);
    return IDBStore.instance;
  }

  static removeInstance() {
    IDBStore.instance = null;
    return IDBStore.instance;
  }

  withIDBStore(
    mode: IDBTransactionMode,
    callback: (store: IDBObjectStore) => void
  ) {
    return this.dbp.then(
      (db) =>
        new Promise((resolve, reject) => {
          const transaction = db.transaction(this.storeName, mode);
          transaction.oncomplete = () => resolve(true);
          transaction.onerror = () => reject(transaction.error);
          callback(transaction.objectStore(this.storeName));
        })
    );
  }

  get<T>(key: string) {
    let req: IDBRequest<T>;
    return this.withIDBStore('readonly', (localStore) => {
      req = localStore.get(key);
    }).then(() => req.result);
  }

  set<T>(key: string, value: T) {
    return this.withIDBStore('readwrite', (localStore) => {
      localStore.put(value, key);
    });
  }

  del(key: string) {
    return this.withIDBStore('readwrite', (localStore) => {
      localStore.delete(key);
    });
  }

  clear() {
    return this.withIDBStore('readwrite', (localStore) => {
      localStore.clear();
    });
  }

  getAll<T>() {
    let req: IDBRequest<T[]>;
    return this.withIDBStore('readonly', (localStore) => {
      req = localStore.getAll();
    }).then(() => req.result);
  }
}
