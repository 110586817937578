import React, { memo, useMemo } from "react";
import { chessSide, flipTurn } from "../../core";
import { parseFen } from "../../core/fen/_parseFen";
import { cgDataTypes } from "../../ui";
import { eLastMoveTheme } from "../../ui/_settings.types";
import { LastMove } from "./_LastMove";
import { Pieces } from "./_Pieces";
import { Titles } from "./_Titles";
import { getChessboardSize } from "../../common/_getChessboardSize";
/**
 * Доска для стрима шахматной партии
 * На ней нельзя играть
 */
export const CGStreamBoard = memo(function CGStreamBoard({ theme, history, lmTheme = eLastMoveTheme.ARROW, chessboardSize = 400, selectedFen, rotate = false, showTitles = false, withPieceHistory = false, }) {
    const lastMove = useMemo(() => {
        if (selectedFen)
            return history.find((item) => item.fen === selectedFen);
        return history[history.length - 1];
    }, [history, selectedFen]);
    const lastMoveLan = useMemo(() => (lastMove === null || lastMove === void 0 ? void 0 : lastMove.lan) || null, [lastMove]);
    const lastTurn = useMemo(() => {
        const lastFen = lastMove === null || lastMove === void 0 ? void 0 : lastMove.fen;
        if (!lastFen)
            return chessSide.WHITE;
        const { nextTurn } = parseFen(lastFen);
        return flipTurn(nextTurn);
    }, [lastMove === null || lastMove === void 0 ? void 0 : lastMove.fen]);
    const boardSize = useMemo(() => getChessboardSize(chessboardSize), [chessboardSize]);
    return (React.createElement("div", { className: theme, "data-cg-type": cgDataTypes.BOARD, "data-theme": theme, style: {
            width: boardSize,
            height: boardSize,
            overflow: "hidden",
            borderRadius: "3px",
            transform: `rotate(${rotate ? 180 : 0}deg)`,
        } },
        lastMoveLan !== null && (React.createElement(LastMove, { theme: lmTheme, lan: lastMoveLan, turn: lastTurn, chessboardSize: boardSize })),
        React.createElement(Pieces, { history: history, rotate: rotate, selectedFen: selectedFen, withPieceHistory: withPieceHistory }),
        showTitles && React.createElement(Titles, { chessboardSize: boardSize, rotate: rotate })));
});
